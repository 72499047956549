<!--  -->
<template>
  <div class="approvalList">
    <div class="top">
      <van-form @submit="handleSearch">
        <div class="search-box">
          <input
            type="text"
            v-model="queryParams.searchContent"
            placeholder="请输入关键字"
            class="search-input"
          />
          <img :src="closeIcon" alt="" class="close-icon" @click="clearInput" />
        </div>
      </van-form>

      <div class="filter" @click="handleSearch">
        <img :src="searchIcon" alt="" class="filter-icon" />
        <span class="text">搜索</span>
      </div>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="handleLoad"
      v-if="list && list.length"
    >
      <ul class="approval-ul">
        <li v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <approval-item :info="item" />
        </li>
      </ul>
    </van-list>
    <van-empty v-else description="暂无数据" :image-size="60" />

    <van-popup
      class="popup"
      v-model="showPopup"
      position="bottom"
      :style="{ maxHeight: '60vh' }"
    >
      <div class="header">审批筛选</div>
      <div class="sub-title" v-if="3 != searchType">状态</div>
      <ul class="status" v-if="3 != searchType">
        <template v-for="(item, index) in status">
          <li
            :class="{ li: true, check: item.value == queryParams.status }"
            @click="changeStatus(item.value)"
            :key="index"
            v-if="item.used.indexOf(searchType) > -1"
          >
            {{ item.lable }}
          </li>
        </template>
      </ul>
      <div class="sub-title">类型</div>
      <ul class="type">
        <li
          :class="{ li: true, check: !queryParams.type }"
          @click="changeType('')"
        >
          全部
        </li>
        <li
          :class="{ li: true, check: item.dictKey == queryParams.type }"
          v-for="(item, index) in types"
          :key="index"
          @click="changeType(item.dictKey)"
        >
          {{ item.dictValue }}
        </li>
      </ul>
      <div class="bottom">
        <van-button class="reset" @click="handleReset">重置</van-button>
        <van-button class="confirm" @click="handleConfirm">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import closeIcon from "@/assets/images/approveList/closeIcon.png";
import searchIcon from "@/assets/images/approveList/searchIcon.png";
import filterIcon from "@/assets/images/approveList/filterIcon.png";
import { taskPage } from "@/api/processApplyInfo";
import ApprovalItem from "./components/ApprovalItem.vue";
import { queryProcessType, queryProcessInfoList } from "@/api/processInfo";

export default {
  data() {
    return {
      searchIcon,
      closeIcon,
      filterIcon,
      list: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        processCode: "",
        status: "",
        type: "",
        searchContent: "",
        descs: "",
      },
      status: [
        { lable: "全部", value: "", used: [4, 1, 2] },
        { lable: "审批中", value: "1", used: [3, 1, 2] },
        { lable: "审批通过", value: "2", used: [4, 1, 2] },
        { lable: "审批驳回", value: "3", used: [4, 1, 2] },
        { lable: "已撤销", value: "4", used: [1, 2] },
      ],
      showPopup: false,
      types: [],

      loading: false,
      finished: false,
    };
  },
  name: "approvalList",
  components: { ApprovalItem },
  computed: {
    searchType() {
      return Number(this.$route.query.searchType);
    },
  },
  filters: {
    formatUrgent(val) {
      const rules = {
        1: "否",
        2: "是",
      };
      return rules[val] || "";
    },
  },
  mounted() {
    this.getList();
    this.getTypesList();
  },

  methods: {
    getList() {
      taskPage({
        ...this.queryParams,
        searchType: this.$route.query.searchType,
        descs: this.searchType == "4" ? "apply_time" : "urgent,apply_time",
      })
        .then((res) => {
          this.list = this.list.concat(res.data.records);
          this.queryParams.pageNum++;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTypesList() {
      queryProcessType().then((res) => {
        this.types = res.data;
      });
    },
    goDetail(item) {
      let path = "/approvalDetail";
      if (item.tagKey && item.tagKey == "PURCHASE_APPROVE") {
        path = `/approvalProcureDetail`;
      }
      if (item.tagKey && item.tagKey == "CAR_APPLY_APPROVE") {
        path = "/approvalCarDetail";
      }
      this.$router.push({
        path: path,
        query: {
          bizCode: item.bizCode,
          processCode: item.processCode,
          searchType: this.$route.query.searchType,
          applyNo: item.applyNo,
          tagKey: item.tagKey || "",
        },
      });
    },
    handleLoad() {
      this.getList();
    },

    changeStatus(code) {
      this.queryParams.status = code;
    },
    changeType(code) {
      this.queryParams.type = code;
    },

    handleReset() {
      this.queryParams.type = "";
      this.queryParams.status = "";
    },
    handleConfirm() {
      this.showPopup = false;

      this.list = [];
      this.queryParams.pageNum = 1;
      this.finished = false;
      this.getList();
    },

    clearInput() {
      this.queryParams.searchContent = "";
      this.list = [];
      this.queryParams.pageNum = 1;
      this.finished = false;
      this.getList();
    },
    handleSearch() {
      this.list = [];
      this.queryParams.pageNum = 1;
      this.finished = false;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.approvalList {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .top {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-box {
      width: 5.92rem;
      height: 0.6rem;
      background: #ffffff;
      border-radius: 0.3rem;
      border: 1px solid #999999;
      display: flex;
      align-items: center;
      padding-left: 0.24rem;
      .search-input {
        flex: 1;
        height: 0.4rem;
        background: #ffffff;
        border: none;
        width: 2rem;
      }
      .close-icon {
        height: 0.24rem;
        margin-right: 0.18rem;
      }
    }
    .filter {
      padding: 0 0.1rem;
      .filter-icon {
        widows: 0.24rem;
        height: 0.24rem;
        margin-right: 0.04rem;
      }
      .text {
        height: 0.3rem;
        font-size: 0.28rem;
        font-weight: 500;
        color: #666666;
        line-height: 0.3rem;
      }
    }
  }
  .approval-ul {
    margin-top: 0.2rem;
  }
  .popup {
    padding: 0 0.3rem;
    .header {
      height: 0.45rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.45rem;
      display: flex;
      margin-top: 0.24rem;
      margin-bottom: 0.3rem;
      justify-content: center;
    }
    .sub-title {
      margin-bottom: 0.2rem;
    }
    .status {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-columns: 0.6rem;
      grid-column-gap: 0.3rem;
      grid-row-gap: 0.3rem;
      grid-auto-rows: 0.6rem;
      margin-bottom: 0.3rem;
    }
    .type {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 0.6rem;
      grid-column-gap: 0.3rem;
      grid-row-gap: 0.3rem;
    }
    .li {
      background: #f9f9f9;
      border-radius: 0.08rem;
      width: 100%;
      height: 100%;
      font-size: 0.24rem;
      color: #666666;
      line-height: 0.28rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .li.check {
      background: #ffffff;
      border: 1px solid #0094ee;
      color: #0094ee;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      .reset {
        width: 3.3rem;
        height: 0.8rem;
        background: #e5f4fd;
        border-radius: 0.16rem;
        font-size: 0.26rem;
        color: #0094ee;
        line-height: 0.37rem;
      }
      .confirm {
        width: 3.3rem;
        height: 0.8rem;
        background: #0094ee;
        border-radius: 0.16rem;
        font-size: 0.26rem;
        color: #ffffff;
        line-height: 0.37rem;
      }
    }
  }
}
</style>
