<!--  -->
<template>
  <div class="approval-li">
    <div class="row1">
      <div class="title">{{ info.applyTitle }}</div>
      <div class="time">{{ info.applyTime }}</div>
    </div>
    <div class="msg" v-for="(item, index) in designValue" :key="index">
      <span>{{ item.title }}:{{ item.value }}</span>
    </div>
    <div class="row5">
      <div class="submit-from">
        由 {{ info.formInfo.formValue.userName }} 提交
      </div>
      <div class="hurry" v-if="2 == info.urgent">
        {{ info.urgent | formatUrgent }}
      </div>
      <div
        :class="{
          doing: true,
          approving: 1 == info.status,
          accept: 2 == info.status,
          reject: 3 == info.status,
          cancel: 4 == info.status,
        }"
      >
        {{ info | formatHisActivity }}
      </div>
    </div>
  </div>
</template>

<script>
import { cartList } from "@/api/cart/manage/index.js";
export default {
  data() {
    return {
      carList: [],
    };
  },
  name: "approvalList",
  components: {},
  props: {
    info: Object,
  },
  computed: {
    designValue() {
      let configForm = JSON.parse(this.info.formInfo.configForm);
      let formContent = JSON.parse(this.info.formInfo.formValue.formContent);
      for (let i in configForm) {
        let field = configForm[i].field;

        if (field === "cardType") {
          configForm[i].value = formContent[field] == "1" ? "是" : "否";
        } else if (field === "cartCode") {
          let obj = this.carList.find((e) => {
            return e.bizCode == formContent[field];
          });
          configForm[i].value = obj ? obj.platingNumber : "";
        } else if (field === "passengers") {
          configForm[i].value = formContent[field]
            .map((e) => {
              return e.userName;
            })
            .toString();
        } else {
          if (configForm[i].type == "PassInput") {
            configForm[i].value = this.randomStr(formContent[field]);
          } else if (configForm[i].type == "timeRange") {
            configForm[i].value = this.formatArray(formContent[field]);
          } else if (configForm[i].type == "ALink") {
            configForm[i].value = configForm[i].props
              ? configForm[i].props.text
              : "";
          } else {
            configForm[i].value = formContent[field];
          }
        }
      }
      configForm = configForm.filter((item) => {
        return ["UploadImg", "UploadFile"].indexOf(item.type) == -1;
      });
      configForm = configForm.sort((a, b) => {
        if (b.$required && !a.$required) {
          return -1;
        } else {
          return 1;
        }
      });

      return configForm.slice(0, 3);
    },
  },
  filters: {
    formatUrgent(val) {
      const rules = {
        1: "否",
        2: "加急",
      };
      return rules[val] || "";
    },
    formatHisActivity(info) {
      let rules = {
        1: "审批中",
        2: "审批通过",
        3: "审批驳回",
        4: "已撤销",
      };
      if (1 == info.status) {
        let hisActivityList = info.nodeInfo.hisActivityList;
        let obj = hisActivityList.find((item) => {
          return item.isFinish == 0;
        });
        return `${obj.assigneeName}${rules[info.status] || ""}`;
      } else {
        return rules[info.status] || "";
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatArray(value) {
      let str = "";
      if (Array.isArray(value)) {
        str = value.join("~");
      } else {
        str = value;
      }
      return str;
    },
    randomStr(value) {
      let str = "";
      if (!value) return "";
      let arr = value.length;
      for (let i = 0; i < arr; i++) {
        str += "*";
      }
      return str;
    },
    async getList() {
      await cartList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.carList = res.data.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-li {
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
  padding: 0.2rem;
  .row1 {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.42rem;
    }
    .time {
      height: 0.3rem;
      font-size: 0.22rem;
      color: #999999;
      line-height: 0.3rem;
      white-space: nowrap;
    }
  }
  .msg {
    word-break: break-all;
    font-size: 0.26rem;
    color: #999999;
    line-height: 0.37rem;
    margin-bottom: 0.12rem;
  }
  .row5 {
    display: flex;
    align-items: top;
    align-items: flex-start;
    .submit-from {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
      flex: 1;
      white-space: nowrap;
      padding: 0.06rem;
    }
    .hurry {
      background: #feefeb;
      border-radius: 0.08rem;
      font-size: 0.26rem;
      color: #f65f38;
      line-height: 0.37rem;
      padding: 0.06rem 0.12rem;
      white-space: nowrap;
      margin-left: 0.2rem;
    }
    .doing {
      border-radius: 0.08rem;
      font-size: 0.26rem;
      color: #fff;
      line-height: 0.37rem;
      padding: 0.06rem 0.12rem;
      margin-left: 0.2rem;
    }
    .approving {
      background: #1989fa;
    }
    .accept {
      background: #07c160;
    }
    .reject {
      background: #ee0a24;
    }
    .cancel {
      background: #999;
    }
  }
}
</style>
